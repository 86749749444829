import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const BgElem = styled.section`
  padding: 30px;
  overflow: visible;
  background: ${(props) => props.bgColor};
  * {
    color: ${(props) => props.color};
  }

  &.image {
    padding: 0;

    img {
      width: 100%;
      display: block;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  > div {
    text-align: center;
    padding: 30px;
    font: 18px / 24px "Slate Light";

    p {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 16px;
      }
    }

    h6 {
      font: 18px / 24px "Slate Regular";
      margin-bottom: 16px;
      
    }

    .lead {
      font: 21px / 30px "Slate Light";

      b,
      strong {
        font-family: "Slate Medium";
      }
    }

    b,
    strong {
      font-family: "Slate Regular";
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 60px;

    &.rcorner {
      border-bottom-right-radius: 140px;
    }
    &.image {
      padding: 60px;
      img {
        margin-bottom: 60px;
      }
    }
    vvv > div {
      text-align: center;
      margin-top: -30px;

      p {
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 16px;
        }
      }

      h6 {
        font: 18px / 24px "Slate Regular";
        margin-bottom: 24px;
      }
    }
  }
`;

const FWBackground = ({ content, bgColor, color, img, roundedCorner }) => (
  <BgElem
    bgColor={bgColor}
    color={color}
    className={
      img && roundedCorner
        ? "image rcorner"
        : img
        ? "image"
        : roundedCorner
        ? "rcorner"
        : ""
    }
  >
    {img ? <img src={img} /> : ""}
    {/* change below div to dangerouslySetInnerHTML & to only display if content prop exists, like content ? <div>... because of spacing */}
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </BgElem>
);

export default FWBackground;
