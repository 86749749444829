import { graphql, StaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import Komittee from "./Komittees/Komittee";
import SectionTitle, { TitleElem } from "./Text/SectionTitle";

const MembersFlex = styled.div`
  width: 100%;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    div {
      width: 33.3%;
    }
  }

  /* @media screen AND (min-width: ${breakpoints.desktop}px) {
    div {
      width: 33.3%;
    }
  } */
`;

const Komittees = () => (
  <StaticQuery
    query={graphql`
      query komittees {
        allKomittee {
          edges {
            node {
              komitteeWithCategory {
                komitteeCategory
                komitteeSubCategory
                isCategory
                attributes {
                  name
                  urlPath
                  description
                  teaser
                  content
                  image {
                    fullsize
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data: any) => {
      if(data && data.allKomittee){
        console.log('data',data)
        const komittees = data.allKomittee.edges.map(({ node } = komittee) => {
          const { komitteeCategory, komitteeSubCategory, isCategory } = node.komitteeWithCategory;
          const komitteeSlug = komitteeCategory.split("-komittee")[0];
          console.log('cat',komitteeCategory,'subcat',komitteeSubCategory)
          let afterBaseUrl = "";
          if (typeof window !== "undefined") {
            afterBaseUrl = window.location.href.substring(
              window.location.origin.length
            );
          }

          if (
            typeof window !== "undefined" &&
            window.location.href.includes(komitteeSlug)
          ) {
            /* */
            if(isCategory){
            console.log('iscat',node.komitteeWithCategory.attributes)
              return(
              <TitleElem dangerouslySetInnerHTML={{ __html: node.komitteeWithCategory.attributes.teaser }}></TitleElem>);
            }
            return (
              <Komittee
                imgurl={node.komitteeWithCategory.attributes.image.fullsize}
                komitteeTitle={node.komitteeWithCategory.attributes.teaser}
                urlPath={node.komitteeWithCategory.attributes.urlPath}
              />
            );
          }
          if (
            typeof window !== "undefined" &&
            afterBaseUrl === "/" &&
            komitteeSlug === "homepage"
          ) {
            return (
              <Komittee
                imgurl={node.komitteeWithCategory.attributes.image.fullsize}
                komitteeTitle={node.komitteeWithCategory.attributes.teaser}
                urlPath={node.komitteeWithCategory.attributes.urlPath}
              />
            );
          }
        });
      
      // remove all undefined elements
      const filtered = komittees.filter((x) => x !== undefined);
      if(filtered.length > 0){
       return <>
                <MembersFlex>{komittees}</MembersFlex>
              </>
      }

    }
    }}
  />
);

export default Komittees;
