import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

export const TitleElem = styled.h2`
  text-align: center;
  width:100%;
  text-transform: uppercase;
  font: 34px / 41px "Slate Light";
  margin-bottom: 31px;

  strong,
  b {
    font-family: "Slate Medium";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 52px / 62px "Slate Light";
    margin-bottom: 67px;
  }
`;

const SectionTitle = ({ children }) => <TitleElem>{children}</TitleElem>;

export default SectionTitle;
